:root {
  --primary-color: #1da57a;
  --primary-text-color: #ffffff;
  --primary-subdued: #426a54;
  --text-subdued: #bbbbbb;
  --secondary-color: #319cbe;
  --background-dark: #001628;
  --background-medium: #2d476b;
  --background-card: #001f39;
  --background-popover: #2d476b;
  --background-dropdown: #0b2942;
  --success-color: #49aa19;
  --warning-color: #d89613;
  --error-color: #a61e24;
  --hover-color: orange;
  --field-outline-color: #434343;
  --subdued-color: #898989;
  --focus-border: #e9cc4e;
  --focus-shadow: green;
  --focus-background: #263238;
  --mask-background: rgba(0, 0, 0, 0.4);
  --error-color: red;
  --info-button-color: var(var(--primary-color));
  --info-button-background: var(var(--background-dark));
  --info-button-border: var(--primary-color);
  --whatever: red;
}

.p-button,
.p-tag {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover {
  border-color: var(--primary-color);
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: var(--primary-color);
}

.number-input-short .p-inputnumber-input {
  max-width: 4rem;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-selectbutton {
  .p-button:first-child {
    border-radius: 1rem 0 0 1rem;
  }

  .p-button:last-child {
    border-radius: 0 1rem 1rem 0;
  }
}

.p-tag {
  border-radius: 1rem;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background-color: var(--primary-color);
}
